import React from "react";
interface ListItemProps {
  id: number;
  label: string;
  icon: string;
  setSelectedLanguageIndex: (index: number) => void;
  selectedIndex: number;
}
export default function ListItem({
  id,
  label,
  icon,
  setSelectedLanguageIndex,
  selectedIndex
}: Readonly<ListItemProps>) {
  const handleChange = () => {
    setSelectedLanguageIndex(id);
  };
  return <div className="flex justify-between items-center mb-[0.75rem]  cursor-pointer bg-[#F7F7F7] w-full h-[3.75rem] px-[1.5rem] rounded-lg" onClick={handleChange} data-sentry-component="ListItem" data-sentry-source-file="list-items.tsx">
      <div className="flex items-center gap-[1rem]">
        <img src={icon} alt={`${label} flag`} className="w-[30px] h-[20px] " />
        <span className="text-gray-900">{label}</span>
      </div>
      {selectedIndex === id && <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
          <path d="M11.4625 15.3251L8.94594 12.8083C8.79589 12.6584 8.60731 12.5817 8.38017 12.5781C8.15321 12.5747 7.96119 12.6514 7.8041 12.8083C7.6472 12.9654 7.56875 13.1557 7.56875 13.3792C7.56875 13.6027 7.6472 13.793 7.8041 13.9501L10.777 16.9231C10.9729 17.1188 11.2014 17.2166 11.4625 17.2166C11.7236 17.2166 11.9521 17.1188 12.148 16.9231L18.1751 10.8959C18.325 10.7459 18.4017 10.5573 18.4053 10.3302C18.4088 10.1032 18.332 9.91119 18.1751 9.7541C18.018 9.5972 17.8277 9.51875 17.6042 9.51875C17.3807 9.51875 17.1904 9.5972 17.0333 9.7541L11.4625 15.3251ZM13.0019 23.2917C11.5784 23.2917 10.2404 23.0216 8.98792 22.4814C7.7354 21.9412 6.64593 21.208 5.7195 20.2819C4.79307 19.3559 4.05956 18.2668 3.51898 17.0149C2.97858 15.7629 2.70837 14.4253 2.70837 13.0019C2.70837 11.5784 2.97849 10.2404 3.51871 8.98792C4.05893 7.7354 4.79208 6.64593 5.71815 5.7195C6.64421 4.79307 7.73324 4.05956 8.98521 3.51898C10.2372 2.97858 11.5748 2.70837 12.9981 2.70837C14.4216 2.70837 15.7597 2.97848 17.0122 3.51871C18.2647 4.05893 19.3542 4.79208 20.2806 5.71815C21.207 6.64421 21.9405 7.73324 22.4811 8.98521C23.0215 10.2372 23.2917 11.5748 23.2917 12.9981C23.2917 14.4216 23.0216 15.7597 22.4814 17.0122C21.9412 18.2647 21.208 19.3542 20.2819 20.2806C19.3559 21.207 18.2668 21.9405 17.0149 22.4811C15.7629 23.0215 14.4253 23.2917 13.0019 23.2917Z" fill="#378C5E" />
        </svg>}
    </div>;
}