"use client";

import ListItem from "@/components/list-items";
import { getDictionary } from "@/lib/dictionary";
import app from "@/utils/firebase/firebase";
import useFcmToken from "@/utils/hooks/useFcmToken";
import { getMessaging, onMessage } from "firebase/messaging";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { Locale } from "../../../../i18n.config";
import { languages } from "@/data/languages";
import { Language } from "@/data/types/languages";
type LanguageCode = keyof typeof languageCodes;
type Translations = {
  select: string;
  nextButton: string;
  welcome: string;
  delivery: string;
};
const languageCodes = ({
  ar: "العربية",
  en: "English",
  fr: "Français",
  me: "Hassaniya",
  ff: "Pulaar",
  sn: "Soninké",
  wo: "Wolof"
} as const);

// Define Home component
export default function Home({
  params: {
    lang
  }
}: Readonly<{
  params: {
    lang: Locale;
  };
}>) {
  const [translations, setTranslations] = useState<Translations>({
    select: "",
    nextButton: "",
    welcome: "",
    delivery: ""
  });
  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState<number>(languages.findIndex(language => language.name === languageCodes[(lang as LanguageCode)]));
  const router = useRouter();
  const {
    notificationPermissionStatus
  } = useFcmToken();
  useEffect(() => {
    async function loadTranslations() {
      const dict = await getDictionary(lang);
      setTranslations(dict.pages.language);
    }
    loadTranslations();
  }, [lang]);
  useEffect(() => {
    const handleForegroundPushNotification = (payload: any) => {
      console.log("Foreground push notification received:", payload);
      console.log("Notification Permission Status:", notificationPermissionStatus);
      if (payload.notification) {
        const {
          title = "Notification",
          body = "Notification Body"
        } = payload.notification;
        new Notification(title, {
          body
        });
      } else {
        console.log("Payload does not contain notification data.");
      }
    };
    if (typeof window !== "undefined" && "serviceWorker" in navigator) {
      const messaging = getMessaging(app);
      const unsubscribe = onMessage(messaging, handleForegroundPushNotification);
      return () => unsubscribe();
    }
  }, [notificationPermissionStatus]);
  const handleLanguageChange = (language: Language) => {
    const langCode = (Object.entries(languageCodes).find(([_, value]) => value === language.name)?.[0] as LanguageCode | undefined);
    if (langCode) {
      router.push(`/${langCode}/language-choosing`);
      setSelectedLanguageIndex(languages.findIndex(lang => lang.name === language.name));
    } else {
      console.error("Language not supported:", language.name);
    }
  };
  const handleNext = () => {
    const twentyYearsLater = new Date();
    twentyYearsLater.setFullYear(twentyYearsLater.getFullYear() + 20);
    document.cookie = `lang=${lang}; path=/; expires=${twentyYearsLater.toUTCString()};`;
    router.push(`/${lang}/signin`);
  };
  return <main className="flex flex-col min-h-screen justify-between px-[1.25rem]" data-sentry-component="Home" data-sentry-source-file="page.tsx">
      <div className="flex flex-col items-center pt-[0.75rem] mb-[2.5rem]  ">
        <img src="/assets/icons/Livii-Logo.svg" alt="Livii Logo" className="w-[26px] h-[26px] " />
      </div>
      <h1 className="text-2xl font-semibold leading-8 text-start">
        <span className="text-gray-900">{translations.welcome} </span>
        <span className="text-green-600">{translations.delivery}</span>
      </h1>
      <div className="">
        <h2 className={`text-[14px]  my-[1.87rem]  font-normal leading-5 text-[#989898]  ${lang === "ar" ? "text-right" : "text-left"}`}>
          {translations.select}
        </h2>
      </div>

      <div className="flex-1  overflow-auto ">
        {languages.map((language, index) => <button key={language.name} onClick={() => handleLanguageChange(language)} className="focus:outline-none w-full text-left">
            <ListItem id={index} label={language.name} icon={language.icon} setSelectedLanguageIndex={setSelectedLanguageIndex} selectedIndex={selectedLanguageIndex} />
          </button>)}
      </div>

      <button className="w-10/12 mx-auto mb-[4.63rem] rounded-xl bg-mainBG px-[1rem] text-white hover:bg-mainBGHover h-[3.25rem]" onClick={handleNext}>
        {translations.nextButton}
      </button>
    </main>;
}