
// Define constants

import { Language } from "./types/languages";


export const languages: Language[] = [
  { value: "ar", name: "العربية", icon: "/assets/icons/ksa.svg" },
  { value: "fr", name: "Français", icon: "/assets/icons/fr.svg" },
  { value: "en", name: "English", icon: "/assets/icons/usa.svg" },
];
